import {Injectable} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router) {

  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated().pipe(
      catchError(() => of(false)), // do not let the user access the route if authentication can neither be confirmed nor denied
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          this.router.navigate(['login']); // redirect user to login if they're not authenticated
        }
      })
    );
  }
}
