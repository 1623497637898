<button
  [ngClass]="{
    'btn-danger':color === 'red' && !state,
    'btn-success':color === 'green' && !state,
    'btn-warning':color === 'yellow' && !state,
    'btn-primary':color === 'default' && !state,
    'btn-outline-secondary': state === 'PENDING'
  }"
  class="state-btn btn"
  [disabled]="deactivated">
  <span *ngIf="!state">{{name | translate}}</span>
  <span *ngIf="state === 'PENDING'" class="loader"></span>
</button>
