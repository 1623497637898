import {Injectable} from '@angular/core';
import {RxStomp} from '@stomp/rx-stomp';
import {StompConfig} from './stomp.config';

@Injectable({
  providedIn: 'root',
})
export class StompService extends RxStomp {

  // using https://github.com/stomp-js/rx-stomp

  constructor() {
    super();

    // set url to use notifications with ur current server if not local
    if (window.location.hostname !== 'localhost') {
      StompConfig.brokerURL = `wss://` + window.location.hostname + ':' + window.location.port + '/api/notification';
    }
  }
}
