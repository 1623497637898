import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-state-button',
  templateUrl: './state-button.component.html'
})
export class StateButtonComponent {
  @Input() state: string;
  @Input() name: string;
  @Input() deactivated: boolean;
  @Input() color?: string = 'default';
}
