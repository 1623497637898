import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
/*
Bobles werden hier gefüllt.
Warum nicht für alle? -> Declined und approved fehlen.
 */

export class HelperService {
    public authorisationJobsInNew: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public authorisationJobsInUpdated: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public authorisationJobsInProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public authorisationJobsInAll: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public invoiceJobsInNew: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public invoiceJobsInUpdated: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public invoiceJobsInProgress: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public invoiceJobsInAll: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor() {
    }
}
