import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UserService} from '../shared/services/user.service';
import {HelperService} from '../shared/services/helper.service';
import {Router} from '@angular/router';
import {StompService} from '../notification/stomp.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html'
})
export class RootComponent implements OnInit, OnDestroy {
  public loginId: number;
  public Subscriptions: Subscription[] = [];


  constructor(private userService: UserService,
              private helperService: HelperService,
              private rxStompService: StompService,
              private router: Router) {

    this.Subscriptions.push(router.events.subscribe(() => {
      const path = this.router.url;
    }));

    this.Subscriptions.push(
      this.userService.userData$.subscribe((res) => {
        if (res) {
          this.loginId = res.loginId;
        }
      })
    )
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.rxStompService.deactivate();
    if (this.Subscriptions) {
      this.Subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }
}
