<div class="header-title ">
  <div class="breadcrumb">
    <ul *ngIf="!url.includes('user-settings')" class="list">
      <li class="item title-grey" [routerLink]="currentId? ['../../'] : ['../']">{{'LOGIN.HEADER' | translate}}</li>
      <li class="item title-grey">-</li>
      <li class="item title-grey" *ngIf="currentId">{{login.username}}</li>
      <li class="item title-grey" *ngIf="currentId">-</li>
      <li class="item">{{(currentId ? 'GENERAL.CHANGE' : 'GENERAL.APPEND') | translate}}</li>
    </ul>
    <ul *ngIf="url.includes('user-settings')" class="list">
      <li class="item title-grey">{{'LOGIN.USER_SETTINGS' | translate}}</li>
      <li class="item title-grey">-</li>
      <li class="item title-grey" *ngIf="currentId">{{login.username}}</li>
      <li class="item title-grey" *ngIf="currentId">-</li>
      <li class="item">{{(currentId ? 'GENERAL.CHANGE' : 'GENERAL.APPEND') | translate}}</li>
    </ul>

  </div>
</div>
<form #loginForm="ngForm">
  <div class="row edit-login">
    <div class="col-4 p-1">
      <div class="data-box">
        <div class="data-header">
          {{'GENERAL.DATA' | translate}}
        </div>
        <div class="form-group row no-gutters">
          <label for="login-name" class="col-2 col-form-label">{{'LOGIN.NAME' | translate}}</label>
          <div class="col-10">
            <input id="login-name" type="text" name="login-name" class="form-control"
                   #name="ngModel"
                   (keydown)="keyDownFunction($event, loginForm.valid)"
                   [readOnly]="(currentId > 0)"
                   [disabled]="!canChangeProfile"
                   [(ngModel)]="login.username"
                   required>
            <span class="show-required-star"></span>
          </div>
        </div>
        <div class="form-group row no-gutters">
          <label for="password" class="col-2 col-form-label">{{'LOGIN.PASSWORD' | translate}}</label>
          <div class="col-10">
            <input id="password" type="password" name="password" class="form-control" #name="ngModel"
                   (keydown)="keyDownFunction($event, loginForm.valid)"
                   [(ngModel)]="login.password">
          </div>
        </div>
        <div class="form-group row no-gutters">
          <label for="email" class="col-2 col-form-label">{{'LOGIN.EMAIL' | translate}}</label>
          <div class="col-10">
            <input id="email" type="text" name="email" class="form-control" #name="ngModel"
                   (keydown)="keyDownFunction($event, loginForm.valid)"
                   [(ngModel)]="login.email"
                   [disabled]="!canChangeProfile"
                   required>
            <span class="show-required-star"></span>
          </div>
        </div>
        <div class="form-group row no-gutters">
          <label for="language"
                 class="col-2 col-form-label">{{'LOGIN.LANGUAGE.DEFAULT' | translate}}</label>
          <div class="col-10">
            <select name="language" class="form-control" id="language"
                    [(ngModel)]="login.locale"
                    [disabled]="!canChangeProfile"
            >
              <option [selected]="login.locale === 'de_DE'" [ngValue]="'de_DE'">{{'LOGIN.LANGUAGE.de_DE' | translate}}</option>
              <option [selected]="login.locale === 'en_GB'" [ngValue]="'en_GB'">{{'LOGIN.LANGUAGE.en_GB' | translate}}</option>
              <option [selected]="login.locale === 'pl_PL'" [ngValue]="'pl_PL'">{{'LOGIN.LANGUAGE.pl_PL' | translate}}</option>
              <option [selected]="login.locale === 'cs_CZ'" [ngValue]="'cs_CZ'">{{'LOGIN.LANGUAGE.cs_CZ' | translate}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="login.teams && login.teams.length>0" class="col-4 p-1">
      <div class="data-box h-100">
        <div class="data-header">
          <div class="d-flex align-items-center new-user">
            {{'LOGIN.TEAM' | translate}}
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <ng-container *ngFor="let team of login.teams">
            <button type="button" class="btn btn-outline-primary m-1"
                    [routerLink]="['../../../team/edit-team/'+team.id]"
                    [queryParams]="{ loginId: login.id}">
              {{team.name}}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="canChangeProfile && login.settings && login.settings.length>0" class="col-4 p-1">
      <div class="data-box h-100">
        <div class="data-header">
          <div class="d-flex align-items-center new-user">
            {{'GENERAL.SETTINGS' | translate}}
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <ng-container *ngFor="let setting of login.settings; let i = index">
            <app-setting-wrapper class="d-block" [setting]="setting"
                                 [label]="setting.settingType"
                                 (outPutSetting)="login.settings[i]= $event"></app-setting-wrapper>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="d-flex mt-5">
  <button *ngIf="!url.includes('user-settings')" type="button" [routerLink]="currentId? ['../../'] : ['../']"
          class="btn btn-outline-secondary"> {{'GENERAL.BACK' | translate}}</button>
  <app-state-button class="ml-auto" (click)="onSubmit($event, loginForm.valid)" [state]="requestState"
                    [deactivated]="!loginForm.valid"
                    [name]="'GENERAL.SAVE'"></app-state-button>
</div>
