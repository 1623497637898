import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RootComponent} from './root/root.component';
import {LoginComponent} from './auth/login/login.component';
import {AuthGuard} from './auth/auth.guard';
import {MainComponent} from './main/main.component';
import {EditLoginComponent} from './administration/administration-login/edit-login/edit-login.component';


export const routes: Routes = [
  {
    path: '',
    data: {state: 'main'},
    component: MainComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: '',
        data: {state: 'root'},
        component: RootComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            redirectTo: 'invoice',
            pathMatch: 'full'
          },
          {
            path: 'user-settings/:id',
            component: EditLoginComponent
          },
          {
            path: 'administration',
            loadChildren: async () => (await import('./administration/administration.module')).AdministrationModule,
          },
          {
            path: 'job',
            loadChildren: async () => (await import('./job/job.module')).JobModule,
          },

        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
