<main class="root full-height full-width">
  <div class="nav-col">
    <app-side-nav></app-side-nav>
  </div>
  <div class="content-col full-height">
    <div class="container-fluid full-height overflow-auto pr-5 pl-5">
    <!--  <app-search-bar></app-search-bar>  -->
      <div class="mt-5">
        <!-- this margin top container goes here, cause the searchbar is removed temporary, so that little space looks better -->
      </div>
      <div id="content">
        <router-outlet #route="outlet"></router-outlet>
      </div>
    </div>
  </div>
</main>
