import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {catchError, map, tap} from 'rxjs/operators';
import {UserService} from '../shared/services/user.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';


@Injectable()
export class AuthService {

  constructor(private translate: TranslateService,
              private toastr: ToastrService,
              private userService: UserService,
              private http: HttpClient,
              private router: Router
  ) {
  }


  public isAuthenticated(): Observable<boolean> {
    return this.getLogin().pipe(
      tap(data => {
        this.userService.userData$.next(data);
      }),
      map(() => {
        return true;
      }),
      catchError(error => {
        this.userService.userData$.next(null);
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return of(false);
        }
        return throwError(error);
      })
    );
  }


  public getLogin(header?: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/session/login`, {headers: header});
  }

  public getLogout(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/session/logout`);
  }

  private btoaUtf8(str: string) {
    return btoa(unescape(encodeURIComponent(str)));
  }

  public login(username?: string, password?: string, fromLogin?: boolean) {
    let credentials: HttpHeaders;

    if (username && password) {
      credentials = new HttpHeaders({Authorization: 'Basic ' + this.btoaUtf8(username + ':' + password)});
    }

    this.getLogin(credentials).subscribe(
      (response: any) => {
        if (username && password) {
            this.router.navigate(['/']);
        }
        return true;
      },
      () => {
        if (fromLogin) {
          this.toastr.error(this.translate.instant('MESSAGES.UNAUTHORIZED'));
        }
        this.router.navigate(['/login']);
        this.userService.userData$.next(null);
        return false;
      });
  }

  public logout(): Observable<any> {
    if (this.userService.wsSubscription) {
      this.userService.client.deactivate().then(() => console.log('deactivated'));
      this.userService.wsSubscription = undefined;
    }
    return this.getLogout().pipe(
      tap(() => {
        this.userService.userData$.next(null);
        this.router.navigate(['login']);
        this.userService.client.deactivate().then(() => console.log('deactivated'));
        this.userService.wsSubscription = undefined;
      })
    );
  }

}
