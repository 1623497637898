import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de-DE'}, // set de-DE as default locale
  ],
  exports: [
    TranslateModule
  ],
})
export class TranslationModule {
}
