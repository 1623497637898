<div class="search-bar">
  <div class="search-bar-content row no-gutters">
    <div class="col-6">
      <div class="search-container w-75">
        <i class="n4icon-search-outline search-icon"></i>
        <input class="search-box" type="search" name="search" placeholder="Search...">
      </div>
    </div>
  </div>
</div>
