import {Component, OnInit} from '@angular/core';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  public username: string;
  public password: string;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
  }

  onSubmit(event: Event, valid: boolean) {
    event.preventDefault();
    if (valid) {
      this.authService.login(this.username, this.password, true);
    }
  }

}
