import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ISetting} from "../../../administration/interfaces/settings.interface";

@Component({
  selector: 'app-setting-wrapper',
  templateUrl: './setting-wrapper.component.html'
})
export class SettingWrapperComponent implements OnInit {
  @Input() public setting: ISetting;
  @Input() public label: string;
  @Output() outPutSetting = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  public checkBoxChanged() {
    this.setting.value = !this.setting.value;
    this.outPutSetting.emit(this.setting);
  }

}
