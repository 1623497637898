import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StateButtonComponent} from '../../components/state-button/state-button.component';
import {TranslateModule} from '@ngx-translate/core';
import {DefaultErrorPageComponent} from '../../components/default-error-page/default-error-page.component';
import {SearchBarComponent} from '../../components/search-bar/search-bar.component';
import {DefaultLoadingPageComponent} from '../../components/default-loading-page/default-loading-page.component';
import {SettingWrapperComponent} from '../../components/setting-wrapper/setting-wrapper.component';
import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from "@angular/forms";


@NgModule({
  declarations: [StateButtonComponent, DefaultErrorPageComponent, DefaultLoadingPageComponent, SearchBarComponent, SettingWrapperComponent],
    imports: [
        ToastrModule.forRoot({
            timeOut: 5000,
            positionClass: 'toast-bottom-right',
            newestOnTop: false,
            progressBar: true,
            progressAnimation: 'increasing',
            tapToDismiss: true
        }),
        CommonModule,
        TranslateModule,
        FormsModule
    ],
  exports: [TranslateModule, StateButtonComponent, DefaultErrorPageComponent, DefaultLoadingPageComponent, SearchBarComponent, SettingWrapperComponent]
})
export class SharedModule { }
