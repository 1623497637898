import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import {TranslationModule} from "../shared/modules/translation/translation.module";
import {FormsModule} from "@angular/forms";



@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    TranslationModule,
    FormsModule
  ]
})
export class AuthModule { }
