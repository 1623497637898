<div class="sidebar">
  <div class="nav-image">
    <img src="assets/images/logo.svg" [routerLink]="['/fleet']">
  </div>
  <div class="nav-menu" [ngClass]="{'low-alpha': showWave}">
    <div class="nav-item">
      <button class="collapsible with-icon" type="button" [ngClass]="{'active': showUserMenu}"
              (click)="showUserMenu = !showUserMenu">
        <i class="n4icon-user-outline"></i><span>{{(userService.userData$ | async)?.userName}}</span>
        <i class="n4icon-arrow-drop-down-outline arrow mr-5 ml-auto"></i></button>
      <div class="content">
        <ul>
          <li>
            <div [routerLink]="['user-settings/' +(userService.userData$ | async)?.loginId]" routerLinkActive="active">
              {{'NAV.PROFILE_SETTINGS' | translate}}
            </div>
          </li>
          <li>
            <div (click)="logout()">
              {{'NAV.LOGOUT' | translate}}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="nav-item">
      <button class="collapsible with-icon position-relative" type="button"
              [routerLink]="['job/']"
              [ngClass]="{'active': showJobMenu}"
              (click)="showJobMenu = !showJobMenu; jobService.jobTypeAndState.next({type:'AUTHORISATION', status: 'ALL'})">
        <i class="n4icon-document-outline"></i>
        <span>{{'NAV.JOBS' | translate}}</span>
        <span *ngIf="(helperService.authorisationJobsInAll |async) > 0"
              class="notify"> {{helperService.authorisationJobsInAll | async}}</span>
        <i class="n4icon-arrow-drop-down-outline arrow mr-5 ml-auto"></i>
      </button>
      <div class="content">
        <ul>
          <li class="position-relative"
              [routerLink]="['job']"
              (click)="jobService.jobTypeAndState.next({type:'AUTHORISATION', status: 'NEW'})">
            {{'NAV.SUBNAV.NEW' | translate}}
            <span *ngIf="(helperService.authorisationJobsInNew |async) > 0"
                  class="notify"> {{helperService.authorisationJobsInNew | async}}</span>
          </li>
          <li class="position-relative" [routerLink]="['job']"
              (click)="jobService.jobTypeAndState.next({type:'AUTHORISATION', status: 'IN_PROGRESS'})">
            {{'NAV.SUBNAV.IN_PROGRESS' | translate}}
            <span *ngIf="(helperService.authorisationJobsInProgress |async) > 0"
                  class="notify"> {{helperService.authorisationJobsInProgress | async}}</span>
          </li>
          <li class="position-relative" [routerLink]="['job']"
              (click)="jobService.jobTypeAndState.next({type:'AUTHORISATION', status: 'UPDATED'})">
            {{'NAV.SUBNAV.UPDATED' | translate}}
            <span *ngIf="(helperService.authorisationJobsInUpdated |async) > 0"
                  class="notify">{{helperService.authorisationJobsInUpdated |async}}</span>
          </li>
          <li class="position-relative" [routerLink]="['job']"
              (click)="jobService.jobTypeAndState.next({type:'AUTHORISATION', status: 'DECLINED'})">
            {{'NAV.SUBNAV.DECLINED' | translate}}
          </li>
          <li class="position-relative" [routerLink]="['job']"
              (click)="jobService.jobTypeAndState.next({type:'AUTHORISATION', status: 'APPROVED'})">
            {{'NAV.SUBNAV.APPROVED' | translate}}
          </li>
        </ul>
      </div>
    </div>
    <div class="nav-item">
      <button class="collapsible with-icon" type="button"
              [routerLink]="['job']"
              [ngClass]="{'active': showInvoiceMenu}"
              (click)="showInvoiceMenu = !showInvoiceMenu; jobService.jobTypeAndState.next({type:'INVOICE', status: 'ALL'})">
        <i class="n4icon-money-cash-outline"></i>
        <span>{{'NAV.INVOICE' | translate}}</span>
        <span *ngIf="(helperService.invoiceJobsInAll |async) > 0"
              class="notify"> {{helperService.invoiceJobsInAll | async}}</span>
        <i class="n4icon-arrow-drop-down-outline arrow mr-5 ml-auto"></i></button>
      <div class="content">
        <ul>
          <li [routerLink]="['job']"
              (click)="jobService.jobTypeAndState.next({type:'INVOICE', status: 'NEW'})">
            {{'NAV.SUBNAV.NEW' | translate}}
            <span *ngIf="(helperService.invoiceJobsInNew |async) > 0"
                  class="notify"> {{helperService.invoiceJobsInNew | async}}</span>
          </li>
          <li [routerLink]="['job']"
              (click)="jobService.jobTypeAndState.next({type:'INVOICE', status: 'IN_PROGRESS'})">
            {{'NAV.SUBNAV.IN_PROGRESS' | translate}}
            <span *ngIf="(helperService.invoiceJobsInProgress |async) > 0"
                  class="notify"> {{helperService.invoiceJobsInProgress | async}}</span>
          </li>
          <li [routerLink]="['job']"
              (click)="jobService.jobTypeAndState.next({type:'INVOICE', status: 'UPDATED'})">
            {{'NAV.SUBNAV.UPDATED' | translate}}
            <span *ngIf="(helperService.invoiceJobsInUpdated |async) > 0"
                  class="notify">{{helperService.invoiceJobsInUpdated |async}}</span>
          </li>
          <li [routerLink]="['job']"
              (click)="jobService.jobTypeAndState.next({type:'INVOICE', status: 'DECLINED'})">
            {{'NAV.SUBNAV.DECLINED' | translate}}
          </li>
          <li [routerLink]="['job']"
              (click)="jobService.jobTypeAndState.next({type:'INVOICE', status: 'APPROVED'})">
            {{'NAV.SUBNAV.APPROVED' | translate}}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="userService.allow('ROLE_ADMIN') || userService.allow('ROLE_MANAGER')  " class="nav-administration"
       [@balloonEffect]="currentState">

    <div *ngIf="currentState === 'initial'" class="settings-open" (click)="changeState()">
      <i class="n4icon-settings-outline"></i>
    </div>

    <div *ngIf="currentState === 'final'" class="settings-close" (click)="changeState()">
      <i class="n4icon-close-outline"></i>
    </div>

    <div *ngIf="showWave" class="wave-container">
      <div style="height: 30px; overflow: hidden;">
        <svg viewBox="0 0 500 150" preserveAspectRatio="none" style="height: 100%; width: 100%;">
          <path d="M0.00,49.98 C162.81,292.59 379.51,-121.88 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
                style="stroke: none; fill: #008580;"></path>
        </svg>
      </div>
    </div>


    <div class="content" *ngIf="currentState === 'final'">
      <ng-container *ngIf="club && club.id">
        <div class="accordion">
          <input checked id="toggle4" type="checkbox" class="accordion-toggle" name="toggle"/>
          <label [routerLink]="['administration/'+ selectedClub?.id]" routerLinkActive="active"
                 for="toggle4"><i class="n4icon-settings-outline"></i>
            <span>{{'NAV.ADMINISTRATION' | translate}}</span>
            <i class="n4icon-arrow-drop-down-outline arrow mr-5 ml-auto"></i>
          </label>
          <section>
            <div [routerLink]="['administration/'+ selectedClub?.id +'/fleet']"
                 routerLinkActive="active">
              {{'NAV.FLEET' | translate}}</div>
            <div [routerLink]="['administration/'+ selectedClub?.id +'/team']"
                 routerLinkActive="active">
              {{'NAV.TEAMS' | translate}}</div>
            <div [routerLink]="['administration/'+ selectedClub?.id +'/login']"
                 routerLinkActive="active">
              {{'NAV.LOGINS' | translate}}</div>
          </section>
        </div>
      </ng-container>
      <ng-container *ngIf="clubs && clubs.length >= 1">
        <div class="menu-settings">
          <i class="n4icon-settings-outline"></i>
          <span class="pt-1">{{'NAV.ADMINISTRATION' | translate}}</span>
        </div>
        <ng-container *ngFor="let club of clubs; let i = index">
          <div (click)="adminService.changeClub(club.id)">
            <div class="accordion">
              <input id="{{i}}" type="checkbox" class="accordion-toggle" name="toggle"/>
              <label [routerLink]="['administration/'+ club.id]"
                     routerLinkActive="active"
                     for="{{i}}">
                <span>{{club.name}}</span>
                <i class="n4icon-arrow-drop-down-outline arrow mr-5 ml-auto"></i>
              </label>
              <section>
                <div [routerLink]="['administration/'+ club.id +'/fleet']"
                     routerLinkActive="active">
                  {{'NAV.FLEET' | translate}}</div>
                <div [routerLink]="['administration/'+ club.id +'/team']"
                     routerLinkActive="active">
                  {{'NAV.TEAMS' | translate}}</div>
                <div [routerLink]="['administration/'+ club.id +'/login']"
                     routerLinkActive="active">
                  {{'NAV.LOGINS' | translate}}</div>
                <div *ngIf="userService.allow('ROLE_ADMIN')"
                     [ngStyle]="{'filter': 'opacity(30%)'}"
                     [routerLink]="['administration/'+ club.id +'/edit-club']"
                     routerLinkActive="active">
                  {{'NAV.EDIT_CLUB' | translate}}
                </div>
              </section>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="userService.allow('ROLE_ADMIN')">
        <div class="platform-settings" [routerLink]="['administration/platform/api-keys']"
             routerLinkActive="active">
          <i class="n4icon-key-outline mr-3"></i>
          {{'NAV.API_KEYS' | translate}}</div>
      </ng-container>
      <ng-container *ngIf="userService.allow('ROLE_ADMIN')">
        <div class="create-club" [routerLink]="['administration/platform/new-club']"
             routerLinkActive="active">
          <i class="n4icon-plus-add-circle-outline mr-3"></i>
          {{'NAV.CREATE_CLUB' | translate}}</div>
      </ng-container>
    </div>
  </div>
</div>
