import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminService} from '../../administration/services/admin.service';
import {Subscription} from 'rxjs';
import {IClub} from '../../administration/interfaces/club.interface';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {JobService} from '../../job/services/job.service';
import {HelperService} from '../../shared/services/helper.service';


@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    animations: [
        trigger('balloonEffect', [
            state('initial', style({
                borderRadius: '50%',
                height: '50px',
                width: '50px',
                bottom: '-8px',
                right: ' -8px'
            })),
            state('final', style({
                borderRadius: '0px',
                height: '50%',
                width: '100%',
                bottom: '0px',
                right: '0px'
            })),
            transition('final=>initial', animate('200ms')),
            transition('initial=>final', animate('200ms'))
        ]),
    ]

})
export class SideNavComponent implements OnInit, OnDestroy {
    public currentState = 'initial';
    public showWave = false;
    public Subscriptions: Subscription[] = []
    public clubs: IClub[];
    public club: IClub;
    public selectedClub: IClub;
    public showUserMenu: boolean;
    public showJobMenu: boolean;
    public showInvoiceMenu: boolean;
    public jobsInAll: number;
    public jobsInNew: number;
    public jobsInProgress: number;
    public jobsInUpdated: number;


    constructor(public adminService: AdminService,
                public jobService: JobService,
                public helperService: HelperService,
                private authService: AuthService,
                public userService: UserService) {
    }

    ngOnInit(): void {
        this.Subscriptions.push(this.userService.userData$.subscribe(user => {
            if (user && user.jobs && user.jobs.AUTHORISATION) {
                this.helperService.authorisationJobsInNew.next(user.jobs.AUTHORISATION.inNew.length);
                this.helperService.authorisationJobsInProgress.next(user.jobs.AUTHORISATION.inProgress.length);
                this.helperService.authorisationJobsInUpdated.next(user.jobs.AUTHORISATION.inUpdated.length);
                this.helperService.authorisationJobsInAll.next(user.jobs.AUTHORISATION.inProgress.length + user.jobs.AUTHORISATION.inUpdated.length + user.jobs.AUTHORISATION.inNew.length);
            }
            if (user && user.jobs && user.jobs.INVOICE) {
                this.helperService.invoiceJobsInNew.next(user.jobs.INVOICE.inNew.length);
                this.helperService.invoiceJobsInProgress.next(user.jobs.INVOICE.inProgress.length);
                this.helperService.invoiceJobsInUpdated.next(user.jobs.INVOICE.inUpdated.length);
                this.helperService.invoiceJobsInAll.next(user.jobs.INVOICE.inProgress.length + user.jobs.INVOICE.inUpdated.length + user.jobs.INVOICE.inNew.length);
            }
        }));
        this.Subscriptions.push(this.adminService.clubs.subscribe(data => this.clubs = data));
        this.Subscriptions.push(this.adminService.club.subscribe(data => this.club = data));
        this.Subscriptions.push(this.adminService.selectedClub.subscribe(data => this.selectedClub = data));
    }

    ngOnDestroy(): void {
        if (this.Subscriptions) {
            this.Subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }

    public logout() {
        this.authService.logout().subscribe();
    }


    public changeState() {
        this.currentState = ((this.currentState === 'initial') ? 'final' : 'initial');
        if (this.currentState === 'final') {
            setTimeout(() => {
                this.showWave = true;
            }, 200);
        }
        if (this.currentState === 'initial') {
            setTimeout(() => {
                this.showWave = false;
            }, 50);
        }
    }

}
