import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Client} from '@stomp/stompjs';
import {HelperService} from './helper.service';
import {StompService} from '../../notification/stomp.service';
import {StompConfig} from '../../notification/stomp.config';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public userData$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public userData: any;
  public client = new Client();
  public wsSubscription;

  constructor(private helperService: HelperService,
              private stompService: StompService,) {
    this.userData$.subscribe((user) => {
      if (user && user != this.userData && user !== undefined) {
        this.stompService.configure(StompConfig);
        this.stompService.activate();
        this.userData = user;
        this.wsSubscription = this.stompService.watch("/user/" + user.userName + "/queue/job-updates").subscribe((frame: any) => {
          if (frame && frame.body) {
            const data = JSON.parse(frame.body.toString());
            //AUTHORISATION
            this.helperService.authorisationJobsInNew.next(data?.jobUpdate?.jobCounts?.AUTHORISATION?.inNew?.length);
            this.helperService.authorisationJobsInProgress.next(data?.jobUpdate?.jobCounts?.AUTHORISATION?.inProgress?.length);
            this.helperService.authorisationJobsInUpdated.next(data?.jobUpdate?.jobCounts?.AUTHORISATION?.inUpdated?.length);
            this.helperService.authorisationJobsInAll.next(data?.jobUpdate?.jobCounts?.AUTHORISATION?.inNew?.length + data?.jobUpdate?.jobCounts?.AUTHORISATION?.inProgress?.length + data?.jobUpdate?.jobCounts?.AUTHORISATION?.inUpdated?.length);
            // INVOICE
            this.helperService.invoiceJobsInNew.next(data?.jobUpdate?.jobCounts?.INVOICE?.inNew?.length);
            this.helperService.invoiceJobsInProgress.next(data?.jobUpdate?.jobCounts?.INVOICE?.inProgress?.length);
            this.helperService.invoiceJobsInUpdated.next(data?.jobUpdate?.jobCounts?.INVOICE?.inUpdated?.length);
            this.helperService.invoiceJobsInAll.next(data?.jobUpdate?.jobCounts?.INVOICE?.inNew?.length + data?.jobUpdate?.jobCounts?.INVOICE?.inProgress?.length + data?.jobUpdate?.jobCounts?.INVOICE?.inUpdated?.length);
          }
        }, (error) => {
          this.stompService.deactivate().then(() => {
            console.log('## Notification Error ##', error);
          });
        });
      }
    })
  }


  public allow(userRoleFor: string) {
    if (this.userData && this.userData.roles) {
      switch (userRoleFor) {
        case 'ROLE_ADMIN':
          if (this.userData.roles.indexOf('ROLE_ADMIN') > -1) return true;
          break;
        case 'ROLE_MANAGER':
          if (this.userData.roles.indexOf('ROLE_MANAGER') > -1) return true;
          break;
        case 'ROLE_OPERATOR':
          if (this.userData.roles.indexOf('ROLE_OPERATOR') > -1) return true;
          if (this.userData.roles.indexOf('ROLE_MANAGER') > -1) return true;
          if (this.userData.roles.indexOf('ROLE_ADMIN') > -1) return true;
          break;
        case 'ROLE_APPROVER':
          if (this.userData.roles.indexOf('ROLE_APPROVER') > -1) return true;
          if (this.userData.roles.indexOf('ROLE_OPERATOR') > -1) return true;
          if (this.userData.roles.indexOf('ROLE_MANAGER') > -1) return true;
          if (this.userData.roles.indexOf('ROLE_ADMIN') > -1) return true;
          break;
        case 'ROLE_AUDITOR':
          if (this.userData.roles.indexOf('ROLE_AUDITOR') > -1) return true;
          if (this.userData.roles.indexOf('ROLE_OPERATOR') > -1) return true;
          if (this.userData.roles.indexOf('ROLE_MANAGER') > -1) return true;
          if (this.userData.roles.indexOf('ROLE_ADMIN') > -1) return true;
          break;
      }
      return false;
    }
  }


}
