import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideNavComponent} from './side-nav/side-nav.component';
import {RouterModule} from '@angular/router';
import {routes} from '../app-routing.module';
import {TranslationModule} from '../shared/modules/translation/translation.module';


@NgModule({
  declarations: [
    SideNavComponent
  ],
  providers: [
    NgModule,
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    TranslationModule
  ],
  exports: [
    SideNavComponent,
    RouterModule
  ]
})
export class SideNavModule {
}
