import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {UserService} from './shared/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public UserSubscription: Subscription;


  constructor(private translateService: TranslateService,
              private userService: UserService) {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  ngOnInit(): void {
    this.UserSubscription = this.userService.userData$.subscribe(response => {
      if (response) {
        this.translateService.use(response.locale.split("_")[0]);
      }
    });
  }

}
