import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule, routes} from './app-routing.module';
import {AppComponent} from './app.component';
import {RootComponent} from './root/root.component';
import {registerLocaleData} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import localeDe from '@angular/common/locales/de';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule
} from "@ngx-translate/core";
import {SideNavModule} from './side-nav/side-nav.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthModule} from './auth/auth.module';
import {AuthService} from './auth/auth.service';
import {MainComponent} from './main/main.component';
import {AuthGuard} from './auth/auth.guard';
import {HttpInterceptor} from './shared/interceptors/http.interceptor';
import {SharedModule} from './shared/modules/shared/shared.module';
import {StompService} from './notification/stomp.service';

registerLocaleData(localeDe);

// AoT requires an exported function for factories
export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export class MyMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    return params.key;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    RootComponent,
    MainComponent,
  ],
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    AppRoutingModule,
    SideNavModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: MyMissingTranslationHandler},
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true},
    HttpClientModule,
    AuthService,
    AuthGuard,
    HttpClient,
    {provide: StompService},
  ],
  exports: [
    TranslateModule,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
