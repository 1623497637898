<div class="user-login-container">
  <div class="sidebar"></div>
  <div class="login-box">
    <div class="user-box">
      <h2>{{'AUTH.TITLE' | translate}}</h2>
      <form #loginForm="ngForm">
        <div class="form-group row">
          <label for="name" class="col-4 col-form-label">{{'AUTH.USERNAME' | translate}}</label>
          <div class="col-8">
            <input id="name" type="text" name="name" class="form-control" #name="ngModel"  [(ngModel)]="username"
                   required>
          </div>
        </div>
        <div class="form-group row">
          <label for="name" class="col-4 col-form-label">{{'AUTH.PASSWORD' | translate}}</label>
          <div class="col-8">
            <input id="password" type="password" name="password" class="form-control" #name="ngModel"  [(ngModel)]="password"
                   required>
          </div>
        </div>
        <div class="btn-container">
          <button class="btn btn-primary" (click)="onSubmit($event, loginForm.valid)" [disabled]="!loginForm.valid">
            <span><strong>{{'AUTH.LOGIN' | translate}}</strong></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
