import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IFleet} from '../interfaces/fleet.interface';
import {AdminRestService} from './rest/admin-rest.service';
import {IClub} from '../interfaces/club.interface';
import {ITeam} from '../interfaces/team.interface';
import {ILogin} from '../interfaces/login';
import {UserService} from '../../shared/services/user.service';
import {ToastrService} from 'ngx-toastr';
import {IApiKey} from '../interfaces/api-key.interface';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  public showErrorPageAdministration: BehaviorSubject<string> = new BehaviorSubject<string>('LOADING');
  public fleets: BehaviorSubject<IFleet[]> = new BehaviorSubject<IFleet[]>(null);
  public teams: BehaviorSubject<ITeam[]> = new BehaviorSubject<ITeam[]>(null);
  public logins: BehaviorSubject<ILogin[]> = new BehaviorSubject<ILogin[]>(null);
  public fleetId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public clubId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public vehicles: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public clubs: BehaviorSubject<IClub[]> = new BehaviorSubject<IClub[]>(null);
  public club: BehaviorSubject<IClub> = new BehaviorSubject<IClub>(null);
  public selectedClub: BehaviorSubject<IClub> = new BehaviorSubject<IClub>(null);
  public apiKeys: BehaviorSubject<IApiKey[]> = new BehaviorSubject<IApiKey[]>(null);
  public _clubId: number;

  constructor(private adminRestService: AdminRestService,
              private translate: TranslateService,
              private toastr: ToastrService,
              private userService: UserService) {

    this.userService.userData$.subscribe(user => {
      if (user) {
        if (this.userService.allow('ROLE_ADMIN')) {
          this.getClubs();
        } else {
          this.getClubById(user.clubId);
        }
      } else {
        this.resetClubData();
      }
    })
  }

  public resetClubData() {
    this.clubId.next(null);
    this.clubs.next(null);
    this.club.next(null);
    this.selectedClub.next(null);
    this.resetClubContentData();
  }

  public resetClubContentData() {
    this.teams.next(null);
    this.vehicles.next(null);
    this.logins.next(null);
    this.fleets.next(null);
  }

  public getVehiclesByFleet(id: number) {
    this.showErrorPageAdministration.next('LOADING');
    this.adminRestService.getVehiclesByFleet(id).subscribe(response => {
        response ? this.vehicles.next(response) : this.vehicles.next([]);
        this.showErrorPageAdministration.next('CONTENT_VEHICLES');
      },
      () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR_GET_DATA'));
        this.showErrorPageAdministration.next('ERROR_VEHICLES');
      });
  }

  public getFleetsByClub(id: number) {
    this.showErrorPageAdministration.next('LOADING');
    this.adminRestService.getFleetsByClub(id).subscribe(response => {
        response ? this.fleets.next(response) : this.fleets.next([]);
        this.showErrorPageAdministration.next('CONTENT_FLEETS');
      },
      () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR_GET_DATA'));
        this.showErrorPageAdministration.next('ERROR_FLEETS');
      });
  }


  public getTeamsByClub(id: number) {
    this.showErrorPageAdministration.next('LOADING');
    this.adminRestService.getTeamsByClub(id).subscribe(response => {
        response ? this.teams.next(response) : this.teams.next([]);
        this.showErrorPageAdministration.next('CONTENT_TEAMS');
      },
      () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR_GET_DATA'));
        this.showErrorPageAdministration.next('ERROR_TEAMS');
      });
  }


  public getLoginsByClub(id: number) {
    this.showErrorPageAdministration.next('LOADING');
    this.adminRestService.getLoginsByClub(id).subscribe(response => {
        response ? this.logins.next(response) : this.logins.next([]);
        this.showErrorPageAdministration.next('CONTENT_LOGINS');
      },
      () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR_GET_DATA'));
        this.showErrorPageAdministration.next('ERROR_LOGINS');
      });
  }


  public getApiKeys() {
    this.showErrorPageAdministration.next('LOADING');
    this.adminRestService.getApiKeys().subscribe(response => {
        response ? this.apiKeys.next(response) : this.apiKeys.next([]);
        this.showErrorPageAdministration.next('CONTENT_API_KEYS');
      },
      () => {
        this.toastr.error(this.translate.instant('MESSAGES.ERROR_GET_DATA'));
        this.showErrorPageAdministration.next('ERROR_API_KEYS');
      });
  }


  public getClubById(id: number) {
    this.adminRestService.getClubById(id).subscribe(data => {
        this.club.next(data);
        this.clubId.next(data.id);
      },
      () =>         this.toastr.error(this.translate.instant('MESSAGES.ERROR_GET_DATA')))
  }


  public getClubs() {
    this.adminRestService.getAllClubs().subscribe(data => this.clubs.next(data),
      () =>         this.toastr.error(this.translate.instant('MESSAGES.ERROR_GET_DATA')))
  }

  public changeClub(id: number) {
    if (this._clubId !== id) {
      this.resetClubContentData();
      this.clubId.next(id);
      this._clubId = id;
      this.adminRestService.getClubById(id).subscribe(response => this.selectedClub.next(response),
        () =>         this.toastr.error(this.translate.instant('MESSAGES.ERROR_GET_DATA')));
    }
  }
}
